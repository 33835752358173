export const images = {
  iconImg: {
    ratio: 1,
    size: {
      minWidth: 40,
      minHeight: 40,
      maxWidth: 600,
      maxHeight: 600,
      fit: 'force'
    },
    inputId: 'shop_vShopPic',
    previewId: 'iconImage',
    minSizeKB: 1,
    maxSizeKB: 200,
    warningClassName: '.icon_image_error'
  },
  serviceBannerImg: {
    ratio: 3 / 1,
    size: {
      minWidth: 300,
      minHeight: 100,
      maxWidth: 3000,
      maxHeight: 1000,
      fit: 'force'
    },
    inputId: 'shop_ppd_detail_vImage',
    previewId: 'image',
    minSizeKB: 1,
    maxSizeKB: 1024,
    warningClassName: '.attachment-error'
  },
  servicePromotedImg: {
    reatio: 3 / 1,
    size: {
      minWidth: 300,
      minHeight: 100,
      maxWidth: 3000,
      maxHeight: 1000,
      fit: 'force'
    },
    inputId: 'shop_ppd_detail_promoted_image',
    previewId: 'promoted_image',
    minSizeKB: 1,
    maxSizeKB: 1024,
    warningClassName: '.attachment-error'
  },
  homeScreenPromotionBannerImg: {
    ratio: 3 / 1,
    size: {
      minWidth: 300,
      minHeight: 100,
      maxWidth: 3000,
      maxHeight: 1000,
      fit: 'force'
    },
    inputId: 'home_screen_promotion_banner',
    previewId: 'banner',
    minSizeKB: 1,
    maxSizeKB: 1024,
    warningClassName: '.attachment-error'
  },
  homeScreenPromotionBannerImgAr: {
    ratio: 3 / 1,
    size: {
      minWidth: 300,
      minHeight: 100,
      maxWidth: 3000,
      maxHeight: 1000,
      fit: 'force'
    },
    inputId: 'home_screen_promotion_banner_ar',
    previewId: 'banner-ar',
    minSizeKB: 1,
    maxSizeKB: 1024,
    warningClassName: '.attachment-error'
  },
  homeScreenPromotionIcon: {
    ratio: 1,
    size: {
      minWidth: 40,
      minHeight: 40,
      maxWidth: 600,
      maxHeight: 600,
      fit: 'force'
    },
    inputId: 'home_screen_promotion_icon',
    previewId: 'icon',
    minSizeKB: 1,
    maxSizeKB: 200,
    warningClassName: '.icon_image_error'
  },
  shopCategoryImg: {
    ratio: '',
    size: {
      minWidth: 1,
      minHeight: 1,
      maxWidth: 10000,
      maxHeight: 10000,
      fit: 'force'
    },
    inputId: 'shop_category_image',
    previewId: 'shop_category_image_preview',
    minSizeKB: 1,
    maxSizeKB: 1024,
    warningClassName: '.attachment-error'
  },
  announcementImg: {
    ratio: 9 / 16,
    size: {
      minWidth: 90,
      minHeight: 160,
      maxWidth: 1090,
      maxHeight: 1920,
      fit: 'force'
    },
    inputId: 'announcement_image',
    previewId: 'announcement_image_preview',
    minSizeKB: 1,
    maxSizeKB: 1024,
    warningClassName: '.attachment-error'
  },
  promotionTagIcon: {
    ratio: 1,
    size: {
      minWidth: 1,
      minHeight: 1,
      maxWidth: 10000,
      maxHeight: 10000,
      fit: 'cover'
    },
    inputId: 'app_display_primary_section_content_component_promotion_tag_icon',
    previewId: 'promotion_tag_icon_preview',
    minSizeKB: 0,
    maxSizeKB: 1024,
    warningClassName: '.content_component_promotion_tag_icon_error'
  },
  tagBackground: {
    ratio: '',
    size: {
      minWidth: 1,
      minHeight: 1,
      maxWidth: 10000,
      maxHeight: 10000,
      fit: 'cover'
    },
    inputId: 'tag_background',
    previewId: 'tag_background_preview',
    minSizeKB: 0,
    maxSizeKB: 1024,
    warningClassName: '.background-attachment-error'
  },
  tagBanner: {
    ratio: '',
    size: {
      minWidth: 1,
      minHeight: 1,
      maxWidth: 10000,
      maxHeight: 10000,
      fit: 'cover'
    },
    inputId: 'tag_banner',
    previewId: 'tag_banner_preview',
    minSizeKB: 0,
    maxSizeKB: 1024,
    warningClassName: '.banner-attachment-error'
  },
  tagIcon: {
    ratio: '',
    size: {
      minWidth: 1,
      minHeight: 1,
      maxWidth: 10000,
      maxHeight: 10000,
      fit: 'cover'
    },
    inputId: 'tag_icon',
    previewId: 'tag_icon_preview',
    minSizeKB: 0,
    maxSizeKB: 1024,
    warningClassName: '.icon-attachment-error'
  }
}
